import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import './gbacertificate.scss';
import logo from '../../img/EarthID Logo - Primary - BLACK.png'
import logoWhite from '../../Logo-EarthID-white.png'
import favicon from '../../img/EarthID FAVICON - PRIMARY.png'
import check from '../../img/check.png'
import ssiApi from '../../ssiApi.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from "react-router-dom";
import QRCode from 'qrcode.react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import CertificatePDF from './downloadCert';
import { saveAs } from 'file-saver'; // Import the file-saver library
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import CertificateIcon from '../../img/certIcon.png'
import BadgeIcon from '../../img/badgeIcon.png'
import BadgeImg from '../../img/badgeImg.jpeg'
import badgeIcon from '../../img/badge.png'
import Xlogo from '../../img/icons8-x-60.png'
import YellowBadge from '../../img/rosette.svg'
import GbaLogo from '../../img/gbaLogo.png'
import cmsLogo from '../../img/cmsLogo.png'
import cmsGreenLogo from '../../img/CMS_Logo_LawTaxFuture_Maxi_RGB.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLocation, faPhone, faEnvelope, faEllipsisVertical, faIdBadge, faFilePdf, faImage, faShare, faQuestionCircle, faArrowDown, faCaretDown, faPen, faUser, faMedal, faArrowUpRightFromSquare, faCheckCircle, faBitcoinSign, faGlobe, faCross, faCircleXmark, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare, faLinkedin, faYoutubeSquare, faTwitterSquare, faWhatsapp, faFacebookSquare, faWhatsappSquare, faLinkedinIn, faTwitter, faEmber, faBitcoin, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LineShareButton, LinkedinShareButton } from 'react-share';

import { JsonToTable } from "react-json-to-table";
import axios from 'axios';
import { isMobile } from 'react-device-detect';



const moment = require('moment');


const ssiBaseUrl = "https://ssi-test.myearth.id";
const authorizationKey = "01a41742-aa8e-4dd6-8c71-d577ac7d463c";

const privateKey = "6mf4ZKu2ts0V4Dr0sS+YbXYveZOyDezzTJUqa5Ls29BHN4jBTY5we/WW0CgmuNGb3PBvdqqJPhDF2YCMnF4faw==";
const publicKey = "RzeIwU2OcHv1ltAoJrjRm9zwb3aqiT4QxdmAjJxeH2s=";
const issuerDID = "did:earthid:testnet:HveP8Sc6xGJJSZ9sGdDZFdQ6zZM9JZjG5BSg6R1YNuFH;earthid:testnet:fid=0.0.407132";
const userDID = "did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499";




const CMSCert = () => {
    const [responseData, setResponseData] = useState(null);
    const [userName, setUserName] = useState(null);
    const [issuer, setIssuer] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isVerificationLoading, setIsVerificationLoading] = useState([true, true, true, true, true]);
    const [verificationData, setVerificationData] = useState([]);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [showCertificate, setShowCertificate] = useState(true);
    //const [showJson, setShowJson] = useState(true);
    const { issuer1, userName1, certType, certId } = useParams();
   // const userName1 = "subject"
  const [isMobile1, setIsMobile1] = useState(false);

  useEffect(() => {
    console.log('Is a real mobile device:', isMobile);
    setIsMobile1(isMobile);
  }, []);
     


    useEffect(() => {


        const fetchData = async () => {
            try {
                setIsLoading(true);
                
                // Define the vcID statically for now (replace with dynamic value if needed)
               /// const vcID = "67bcba0351e458ceb200571c"; 
                
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
        
                const raw = JSON.stringify({ vcID: certId });
        
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };
        
                const response = await fetch("https://procreddb.myearth.id/api/vcnew/show", requestOptions);
                
                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }
                
                const result = await response.json(); // Assuming JSON response
               // console.log(result);

                if (result && result.response && result.response.length > 0) {
                    setResponseData(result.response); // Store the response data
                  console.log('this is responseData', result.response)
                    setUserName(result.response[0].vc.credentialSubject[0].subjectName)
                    //console.log('this is userName', userName)
                    setIssuer(result.response[0].vc.credentialSubject[0].issuerName)
                } else {
                    console.log('Certificate not generated');
                    Swal.fire('Certificate Not Generated');
                }

        
                
        
            } catch (error) {
                console.error("Error:", error);
                Swal.fire("Error fetching certificate data");
            } finally {
                setIsLoading(false);
            }
        };
        

        fetchData();
    }, []);

    // Remove all non-alphanumeric characters (including spaces)
    let sanitizedUserName
  if(userName){
    sanitizedUserName = userName.replace(/[^a-zA-Z0-9]/g, "");

  }
     


    const qrCodeUrl = `https://procred.myearth.id/v1/en/certificate/${issuer}/${sanitizedUserName}/${certType}/${certId}`;

    const pdfContainerRef = useRef();


    const certificationName = 'CMS equIP Member Certificate';
    const certUrl = `https://procred.myearth.id/v1/en/certificate/${issuer}/${sanitizedUserName}/${certType}/${certId}`;


    const shareSubject = `Exciting News! ${userName} Recognised as a ${issuer} equIP Member`;

const shareBody = `We are excited to share that ${userName} has been recognised as a ${issuer} equIP Member! 🎉

This verifiable certificate has been issued by ${issuer} in partnership with EarthID.
    
#${sanitizedUserName} #${issuer} #EarthID #Innovation
    
🔗 View certificate here:`;

const shareBodyMail = `Dear Recipient,

We are excited to share that ${userName} has been recognised as a ${issuer} equIP Member! 🎉

This verifiable certificate has been issued by ${issuer} in partnership with EarthID.

🔗 View certificate here: 
${certUrl}
        
#${sanitizedUserName} #${issuer} #EarthID #Innovation

Kind Regards,
${userName}`;

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(certUrl)}`;



    function formatExcelDate(dateValue) {
        // Handle Excel number formats (e.g., 45036)
        if (typeof dateValue === 'number') {
          const excelEpoch = new Date(Date.UTC(1899, 11, 30));
          const formatted = new Date(excelEpoch.getTime() + dateValue * 86400000);
          return formatted.toLocaleDateString('en-GB'); // "DD/MM/YYYY"
        }
      
        // Handle string like "2025-03-24T00:00:00Z" or "MM/DD/YYYY"
        if (typeof dateValue === 'string') {
          const parsed = new Date(dateValue);
          if (!isNaN(parsed.getTime())) {
            return parsed.toLocaleDateString('en-GB');
          }
          return dateValue; // Return as-is if not parsable
        }
      
        return '';
      }
      


    useEffect(() => {
        if (isButtonClicked && responseData) {
            //   setIsLoading(true);
            setIsVerificationLoading([true, true, true, true, true]);
            setVerificationData([]);

            setTimeout(() => {
                //setIsLoading(false);
                setIsVerificationLoading([true, true, true, true, true]);
                setVerificationData([
                    moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                    moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                    "EarthID",
                    userName
                ]);

                setTimeout(() => {
                    setIsVerificationLoading([false, true, true, true, true]);
                    setVerificationData([
                        moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                        moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                        "EarthID",
                        userName
                    ]);

                    setTimeout(() => {
                        setIsVerificationLoading([false, false, true, true, true]);
                        setVerificationData([
                            moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                            moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                            "EarthID",
                            userName
                        ]);

                        setTimeout(() => {
                            setIsVerificationLoading([false, false, false, true, true]);
                            setVerificationData([
                                moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                                moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                                "EarthID",
                                userName
                            ]);

                            setTimeout(() => {
                                setIsVerificationLoading([false, false, false, false, true]);
                                setVerificationData([
                                    moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                                    moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                                    "EarthID",
                                    userName
                                ]);

                                setTimeout(() => {
                                    setIsVerificationLoading([false, false, false, false, false]);
                                    setVerificationData([
                                        moment(responseData[0].vc.credentialSubject.startDate).format('MMMM Do YYYY'),
                                        moment(responseData[0].vc.credentialSubject.endDate).format('MMMM Do YYYY'),
                                        "EarthID",
                                        userName
                                    ]);
                                }, 1000);
                            }, 1000);
                        }, 1000);
                    }, 1000);
                }, 1000);
            }, 12000);
        }
    }, [isButtonClicked, responseData]);

    const getYearandMonth = (responseData) => {
        if (responseData && responseData.length > 0) {
            try {
                // Extract dates from credentialSubject[0]
                const rawStartDate = responseData[0].vc.credentialSubject[0].startDate;
                const rawEndDate = responseData[0].vc.credentialSubject[0].endDate;

                const startDate = formatExcelDate(rawStartDate);
                const endDate = formatExcelDate(rawEndDate);
    
                if (!startDate || !endDate) {
                    console.error("Start date or End date is missing.");
                    return null;
                }
    
                // Convert "25/10/2023" -> moment("25/10/2023", "DD/MM/YYYY")
                const issueDate = moment(startDate, "DD/MM/YYYY");
                const expiryDate = moment(endDate, "DD/MM/YYYY");
    
                if (!issueDate.isValid() || !expiryDate.isValid()) {
                    console.error("Invalid date format in credentialSubject.");
                    return null;
                }
    
                // Extract Year & Month
                const issueYear = issueDate.format("YYYY");
                const issueMonth = issueDate.format("MM");
                const expiryYear = expiryDate.format("YYYY");
                const expiryMonth = expiryDate.format("MM");
    
               // console.log(`Issue Year: ${issueYear}, Issue Month: ${issueMonth}`);
              // console.log(`Expiry Year: ${expiryYear}, Expiry Month: ${expiryMonth}`);
    
                return {
                    issueYear,
                    issueMonth,
                    expiryYear,
                    expiryMonth,
                };
            } catch (error) {
                console.error("Error processing dates:", error);
                return null;
            }
        }
        return null;
    };
    

    const extractLastPart = (responseData) => {
        if (responseData && responseData.length > 0 && responseData[0].vc.id) {
            const id = responseData[0].vc.id;
            const parts = id.split('-');
            const lastPart = parts[parts.length - 1];
            return lastPart;
        }
        return null;
    }

    const lastPart = extractLastPart(responseData);

    const dateData = getYearandMonth(responseData)
   // console.log(dateData)

    // const generateLinkedInURL = (certificationName, dateData, certUrl) => {
    //     if (!dateData) {
    //         // Handle the case when dateData is null (responseData is not available or empty)
    //         console.error("dateData is null. Cannot generate LinkedIn URL.");
    //         return null;
    //     }

    //     const linkedInURL = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(
    //         certificationName
    //     )}&name=CMS equIP Member Certificate&organizationName=CMS&issueYear=2023&issueMonth=10&expirationYear=2026&expirationMonth=10&certUrl=${encodeURIComponent(certUrl)}
    //     &certId=${encodeURIComponent(lastPart)}`;

    //     return linkedInURL;
    // };

    const generateLinkedInURL = (certificationName, dateData, certUrl) => {
        if (!dateData) {
            // Handle the case when dateData is null (responseData is not available or empty)
            console.error("dateData is null. Cannot generate LinkedIn URL.");
            return null;
        }

        const organizationId = "7760"; 

        const linkedInURL = `https://www.linkedin.com/profile/add?startTask=${encodeURIComponent(certificationName)}&name=CMS equIP Member Certificate&organizationId=${encodeURIComponent(organizationId)}&issueYear=${encodeURIComponent(dateData.issueYear)}&issueMonth=${encodeURIComponent(
            dateData.issueMonth
        )}&expirationYear=${encodeURIComponent(dateData.expiryYear)}&expirationMonth=${encodeURIComponent(
            dateData.expiryMonth
        )}&certUrl=${encodeURIComponent(certUrl)}&certId=${encodeURIComponent(lastPart)}`;

        return linkedInURL;
    };

    const linkedInURL = generateLinkedInURL(
        certificationName,
        dateData,
        certUrl
    );





    //Verify Api

    const createUserSignature = async (documentMetadata) => {
        try {
            const userDid = await createUserDid();
            const payload = {
                payload: {
                    credentialSubject: {
                        id: userDid,
                        pdfMetaData: documentMetadata,
                    },
                },
            };

            const config = {
                method: 'post',
                url: `${ssiBaseUrl}/api/user/sign?issuerDID=${issuerDID}`,
                headers: {
                    'X-API-KEY': authorizationKey,
                    privateKey,
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(payload),
            };

            const response = await axios.request(config);
          //  console.log(response.data)
            return response.data.Signature;
        } catch (error) {
            console.log(error);
            throw error;
        }
    };


    // const verifyVC = async () => {
    //     try {

    //         //const signature = await createUserSignature();

    //         const data = {
    //             "credentials": {
    //                 ...responseData[0].vc
    //             }
    //         }
    //         const config = {
    //             method: 'post',
    //             url: ssiBaseUrl + "/api/verifier/verify?zpkValidation=false",
    //             headers: {
    //                 'X-API-KEY': authorizationKey,
    //                 'publicKey': publicKey,
    //                 //'userSignature': signature, // Call the createUserSignature function
    //                 'Content-Type': 'application/json'
    //             },
    //             data: JSON.stringify(data)
    //         };

    //         const response = await axios.request(config);
    //         console.log(JSON.stringify(response.data));

    //         return response.data;
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     }
    // }

    const verifyVC = async () => {
        try {
            const verificationPayload = {
                "credential": {
                    ...responseData[0].vc
                }
            };

            const config = {
                method: 'post',
                url: 'https://did.myearth.id/v2/credentials/verify',
                headers: {
                    'accept': 'application/json',
                    'x-api-key': '987e6543-f21c-47e1-b103-435e614174005',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(verificationPayload)
            };

            const response = await axios.request(config);
            console.log('Verification Response:', response.data);

            return response.data; // You can modify how you use the response as per your UI requirements
        } catch (error) {
            console.error('Error verifying VC:', error);
            throw error;
        }
    };



    const createUserDid = async () => {
        // Implement the logic to create a user DID (if required)
        // For now, returning a placeholder value
        return 'did:earthid:testnet:9WzSyPSBkbUQVktjGxmvKAzbTNJCs1PFqo2fQ6BnuHiE;earthid:testnet:fid=0.0.15057499';
    };

    //Verify Api

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
        setIsButtonClicked(true);
        setIsVerifying(true);


        // Call verifyVC() here
      //  verifyVC().then((verificationResult) =>
             try{
                setTimeout(() => {
                    setIsVerifying(false);
                }, 3000);
            // Process the verification result and update state variables accordingly
            // console.log('this is vareification status:', verificationResult.success)
            // if(verificationResult.success && verificationResult.status==200){
            //     setIsVerifying(false);
            // }else{
            //     setIsVerifying(false);
            //     setIsPopupOpen(false)
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Verification Failed',
            //         text: 'The credential could not be verified. Please try again later.',
            //     });
            // }
            
        }catch(error) {
            setIsVerifying(false);
            setIsPopupOpen(false)
            console.error("Verification error:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred during verification. Please check your connection or try again later.',
            });
        };
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    // function toggleView() {
    //     setShowJson(!showJson);
    //   }


    const handleMailClick = () => {
        const subject = encodeURIComponent(shareSubject);
        const body = encodeURIComponent(shareBodyMail.trim());

        const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;

        const a = document.createElement('a');
        a.href = mailtoUrl;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
    };

    const handleViewCertificate = () => {
        const MySwal = withReactContent(Swal);

        MySwal.fire({
            title: 'Digital Certificate(VC)',
            html: <pre>{JSON.stringify(responseData[0].vc, null, 2)}</pre>,
            confirmButtonText: 'Verify',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
                content: 'certificate-swal',
                confirmButton: 'swal-button',
                cancelButton: 'swal-button swal-button--cancel',
            },
            width: '1000px',
            didOpen: () => {
                const verifyButton = MySwal.getConfirmButton();
                verifyButton.addEventListener('click', () => {
                    const loadingSwal = MySwal.mixin({
                        title: 'Loading',
                        text: 'Performing verification...',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                        onOpen: () => {
                            loadingSwal.showLoading();
                            verifyVC()
                                .then(() => {
                                    // Display success message upon successful verification
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Successful',
                                        text: '',
                                        icon: 'success',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                })
                                .catch((error) => {
                                    // Handle error case and display error message
                                    loadingSwal.close();
                                    MySwal.fire({
                                        title: 'Verification Error',
                                        text: 'An error occurred during verification.',
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        allowOutsideClick: true,
                                        allowEscapeKey: true,
                                        showCancelButton: false,
                                        customClass: {
                                            confirmButton: 'swal-button',
                                        },
                                    });
                                });
                        },
                    });

                    loadingSwal.fire();
                });
            },
        });
    };

    const handleToggleCertificate = () => {
        setShowCertificate(true);
    };

    const handleToggleBadge = () => {
        setShowCertificate(false);
    };

    // Function to handle the PDF download
    const handleDownload = () => {
        const pdfContainer = pdfContainerRef.current;
      
        const isMobilepdf = window.innerWidth <= 768;
        const scale = isMobilepdf ? 2.5 : 3;
        const width = isMobilepdf ? 440 : 740;
        const height = isMobilepdf ? 850 : 550;
        const imgWidth = isMobilepdf ? 440 : 742;
        const imgHeight = isMobilepdf ? 850 : 550;
      
        html2canvas(pdfContainer, { scale, useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 1.0);
          const orientation = width > height ? 'l' : 'p';
          const pdf = new jsPDF(orientation, 'pt', [width, height]);
      
          pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
      
          // Create blob with correct MIME type
          const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });
      
          const fileName = `${userName} - ${certificationName}.pdf`;
          saveAs(blob, fileName);
        });
      };
    

    const handleShareLinkedIn = () => {
        // Open the LinkedIn URL in a new tab
        window.open(linkedInURL, '_blank');
    };

    const handleHelpClick = () => {
        Swal.fire({
            title: 'Help',
            html: `
            <div style="text-align: left;">
              <p><strong>How to Use:</strong></p>
              <p>1. To verify the certificate, click on the verify button.</p>
              <p>2. To download the certificate, click on the 'Download' button.</p>
              <p>3. For any assistance, contact support at support@myearth.id</p>
            </div>
          `,
            icon: 'info',
            showCloseButton: true,
            didOpen: () => {
                setTimeout(() => {
                    const closeButton = document.querySelector('.swal2-close');
                    if (closeButton) {
                        closeButton.innerHTML = ""; // Clear default text
                        
                        // ✅ Create a new div to hold the FontAwesome icon
                        const iconElement = document.createElement("div");
                        closeButton.appendChild(iconElement);
                        
                        // ✅ Use React to render FontAwesome inside SweetAlert2 close button
                        ReactDOM.render(<FontAwesomeIcon icon={faCircleXmark} style={{ color: "#7f7f7f", fontSize: "25px"}}/>, iconElement);
                    }
                }, 100);
            },
            showConfirmButton: false,
            customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                closeButton: 'swal-custom-close-button'
            }
        });
    };
    // const handleShareLinkedIn = () => {
    //     // Wait until the LinkedIn SDK is fully loaded
    //     window.onload = function () {
    //       // Access the IN object and use it here
    //       IN.UI.Share().params({
    //         url: 'YOUR_LINKEDIN_SHARE_URL',
    //         mini: true,
    //       }).place();
    //     };
    //   };
    //   const handleViewCertificate = () => {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: 'Digital Certificate(VC)',
    //       html: <pre>{JSON.stringify(responseData[0], null, 2)}</pre>,
    //       confirmButtonText: 'Close',
    //       customClass: {
    //         content: 'certificate-swal',
    //       },
    //     });
    //   };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {responseData && responseData.length > 0 ? (
                <div className="page-container">
                    <nav className="navbar-cert">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                        <ul className="nav-menu">
                            <li className="nav-item"><a onClick={handleHelpClick}>Help</a></li>
                        </ul>
                    </nav>
                    <div className="certificate-container">
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className={showCertificate ? 'certBackgba' : 'badgeBackgba'}>
                                    <div >

                                        {/* <div className="icon-wrapper">
                                    <img
                                        src={CertificateIcon}
                                        alt="Certificate Icon"
                                        className={showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleCertificate}
                                    />
                                    <img
                                        src={BadgeImg}
                                        alt="Badge Icon"
                                        //style={{ height: '150px', width: '130px' }}
                                        className={!showCertificate ? 'active-icon' : 'inactive-icon'}
                                        onClick={handleToggleBadge}
                                    />
                                </div> */}
                                        {showCertificate ? (
                                            <div ref={pdfContainerRef} className="certificatedgbaa">
                                                <div className="bodboxcms">
                                                    <div className='backwatermark'>

                                                        <div className="favicocms">
                                                            <img src={cmsLogo}></img>
                                                        </div>



                                                        <div className="title11cms">{certificationName}</div>

                                                        <div className="title1cms">Presented to</div>


                                                        <div className="body">
                                                            <div className="namecms" style={{ textTransform: 'uppercase', textAlign: 'center' }}>{userName}</div>
                                                            <div className="title1cms">
                                                            {userName} is a recognised member of the CMS equIP Programme, a global tech-focused accelerator initiative curated by CMS to support start-ups. As a recognised equIP member, {userName} has access to the programme’s benefits, including members’  discount on legal fees in all areas of law,  access to the wider equIP network and tech ecosystem to accelerate its scale-up.
                                                            </div>
                                                        </div>

                                                        <div>
                                                            {/* Add the QRCode component */}
                                                            <div className="qrcode-container">
                                                                <QRCode className='qrCodegba' value={qrCodeUrl} style={{ padding: "4px", background: "white" }} />
                                                            </div>
                                                            {/* <div className='signature'>EarthID</div> */}
                                                            {/* <div className='signature1'>KPMG</div> */}
                                                            {/* <div className='gbaLogo'>
                                               <img src={GbaLogo}></img>
                                                </div> */}
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className="title1cms"style={{fontSize: "0.7rem"}} ><em>Powered by EarthID</em></div>
                                            </div>) : (
                                            <div className="badge-containerr">
                                                <img
                                                    src={BadgeImg}
                                                    alt="Badge Icon"
                                                    //style={{ height: '450px', width: '380px' }}
                                                    className={'badgeImgg'}
                                                    onClick={handleToggleBadge}
                                                />
                                            </div>
                                        )}
                                    </div>

                                </div>

                            </div>

                            <div className='rightsec col-md-4'>
                                <div className="footer2tcms">
                                    <div className='verify'>Certificate Verification</div>
                                    <div className='verify1'>
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                        This certificate is from a verified issuer
                                    </div>
                                    <div className='verify1'>
                                        {/* <div className="check">
                                            <img src={check}></img>
                                        </div> */}
                                        <FontAwesomeIcon className='fontIcon21' icon={faCheckCircle} />
                                        Certificate ID: {lastPart}
                                    </div>
                                    <button className="verify-btn" onClick={handlePopupOpen}>
                                        Verify
                                    </button>
                                    {/* <button className='verify-btn' onClick={handleViewCertificate}>View Digital Certificate</button> */}
                                    <button className='verify-btn' onClick={handleDownload}>Download</button>
                                </div>
                                <div className="footer2tcms">
                                    <div className='sharecontainer'>
                                        <div className=''>
                                            <div className='verify'>Share Credential</div>
                                            <div className='verify1'>Show this credential on your social network</div>
                                        </div>
                                        <div className='yellowBadget'>
                                            <img src={badgeIcon}></img>

                                        </div>
                                    </div>

                                    <div className='row iconns-div'>
                                        <a className='shareIcon' onClick={handleMailClick} style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon className='iconns' icon={faEnvelope} />
                                        </a>
                                        <FacebookShareButton
                                            url={certUrl}
                                            //quote={shareBody}
                                            //hashtag="#CentreforEnergyEqualityLTD #CMS #EarthID #Innovation"
                                            //hashtag={shareBody}
                                        >
                                            <FontAwesomeIcon className='iconns' icon={faFacebook} />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={certUrl}
                                            title={shareBody}
                                        >
                                            {/* <FontAwesomeIcon className='iconns' icon={faTwitter} /> */}
                                            <div className='iconns3'>
                                                <img src={Xlogo} className='iconns2'></img>
                                            </div>


                                        </TwitterShareButton>
                                        <WhatsappShareButton
                                            url={certUrl}
                                            title={shareBody}
                                        >
                                            <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                        </WhatsappShareButton>
                                        {/* <LinkedinShareButton
                                                url={certUrl}
                                                summary={shareBody}
                                            >
                                                <FontAwesomeIcon className='iconns' icon={faWhatsapp} />
                                            </LinkedinShareButton> */}
                                        {/* LinkedIn Share (Custom URL) */}
                                        {/* <a className='shareIcon' href={linkedInShareUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='iconns' icon={faLinkedin} />
    </a> */}
                                    </div>
                                    <button 
                                    className='verify-btn' 
                                    id="myButton" 
                                    disabled={isMobile1}
                                    style={{
                                        opacity: isMobile1 ? 0.5 : 1,
                                        cursor: isMobile1 ? "not-allowed" : "pointer"
                                    }}
                                    onClick={handleShareLinkedIn}>

                                        <FontAwesomeIcon className='iconns1' icon={faLinkedin} />  Share on LinkedIn
                                    </button>
                                    {/* <a href="https://www.linkedin.com/company/myearthid/" target="_blank"><FontAwesomeIcon icon={faLinkedin}/></a> */}

                                </div>

                            </div>

                        </div>


                    </div>
                    {/* Add the PDF download button */}
                    {/* Add the download button */}

                    <section className='infosecgba'>
                        <div >
                            <div className='row'>
                                <div className='leftsec col-md-8'>
                                    {/* <a href='https://gbaglobal.org/' target='blank'>
                                        <div className='issuerName'>

                                            <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} />

                                            <div className='gbaLogo1'>
                                               <img src={GbaLogo}></img>
                                                </div>
                                                Government Blockchain Association

                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />


                                        </div>
                                    </a> */}

                                    <div className='row'>
                                        <div className="check col-md-1">
                                            <img src={check}></img>
                                        </div>
                                        <div className='coursename col-md-11'>equIP Member</div>
                                    </div>
                                    {/* <div className='allBtns row'>
                                        <button className='pdfbtn' title='Download Pdf' onClick={handleDownload}>
                                            <FontAwesomeIcon className='fontIcon' icon={faFilePdf} />
                                            PDF
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faImage} />
                                            BADGE
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faShare} />
                                            EMAIL
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faPen} />
                                            EMBED
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>
                                            <FontAwesomeIcon className='fontIcon' icon={faQuestionCircle} />
                                            HELP
                                        </button>
                                        <button className='pdfbtn' title='Feature Currently Disabled'>

                                            MORE
                                            <FontAwesomeIcon className='fontIcon1' icon={faCaretDown} />
                                        </button>
                                    </div> */}
                                    <div className='studNameDiv' style={{ textTransform: 'capitalize' }}>
                                        <FontAwesomeIcon className='fontIcon' icon={faBuilding} />
                                        {userName}
                                    </div>
                                    {/* <a >
                                        <div className='viewCreds'>View All Credentials</div>
                                    </a> */}


                                    {/* <div className='stuID'>ID: EarthID</div> */}


                                    <hr></hr>
                                    <div className='description'>
                                    This certificate acknowledges that {userName} has  met the equIP Programme selection criteria, demonstrating a tech-focused business model and ready to benefit from the programme.
                                    </div>
                                    {/* <div className='skillsdiv'>
                                        <p>Skills/Knowledge</p>
                                        <div className='row'>
                                            <div className='allSkills'>
                                                Javascript
                                            </div>
                                            <div className='allSkills'>
                                                React
                                            </div>
                                            <div className='allSkills'>
                                                Node
                                            </div>
                                            <div className='allSkills'>
                                                Hashgraph
                                            </div>

                                        </div>
                                    </div> */}
                                    <hr></hr>

                                    <div className='row'>
                                        <div className='issueon col-md-6'>
                                            <div>Issued On</div>
                                            <div>{formatExcelDate(responseData[0].vc.credentialSubject[0].startDate)}</div>
                                        </div>
                                        <div className='expiry col-md-6'>
                                            <div>Expires On</div>
                                            <div>{formatExcelDate(responseData[0].vc.credentialSubject[0].endDate)}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='rightsec col-md-4'>

                                    <div className="footer2tcms" style={{ alignItems: 'center' }}>
                                        <div className='verify text-center' style={{ marginBottom: '-30px', fontSize: '1.5rem' }}>About the Issuer</div>
                                        <div className="logowhite" style={{ marginBottom: '0px' }}>
                                            <img src={cmsGreenLogo}></img>
                                        </div>
                                        <div className='description text-center' style={{ marginBottom: '20px', marginTop: '-50px', fontSize: '1.1rem' }}>
                                        This certificate is issued by CMS, a globally recognised law firm dedicated to support tech-focused start-ups founders to 
                                        scale up through its accelerator programme, CMS equIP.  Available in 30 jurisdictions, the CMS equIP programme offers its members 
                                        tailored legal advice with discounted fees, strategic mentorship, and access to a broad international network and tech eco-system. 
                                        <br /> 
                                        <strong>Contact us: </strong> equIP@cms-cmno.com
                                        </div>
                                        {/* <button className="verify-btn" onClick={() => window.open('https://myearth.id', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button> */}
                                        <div className='row iconns-divcms'>
                                            <a className='shareIcon' href='https://cms.law/' target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                            </a>
                                            <a className='shareIcon' href="https://www.linkedin.com/company/cms-cameron-mckenna/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faLinkedinIn} />
                                            </a>
                                            {/* <a className='shareIcon' href="https://www.instagram.com/gbaglobal/" target="_blank">
                                                <FontAwesomeIcon className='iconns' icon={faInstagram} />
                                           </a> */}
                                            {/* <a className='shareIcon' href='https://twitter.com/CMS_law' target="_blank">
                                                
                                                <div className='iconns3'>
                                                    <img src={Xlogo} className='iconns2'></img>
                                                </div>
                                            </a> */}

                                            {/* <a className='shareIcon' href=''>
                                                <FontAwesomeIcon className='iconns' icon={faGlobe} />
                                           </a> */}
                                        </div>
                                        {/* <button className="verify-btn">
                                            View All Credentials
                                        </button> */}
                                    </div>
                                    {/* <div className="footer2">
                                        <div className='verify'>More about the Issuer</div>
                                        <div className='verify1'>Government Blockchain Association</div>
                                        <button className="verify-btn" onClick={() => window.open('https://gbaglobal.org/', '_blank', 'noopener')} rel="noopener">
                                            Visit Issuer Website
                                            <FontAwesomeIcon className='issuerfontIcon' icon={faArrowUpRightFromSquare} />
                                        </button>
                                    </div> */}
                                </div>

                            </div>

                        </div>

                    </section>



                    {isPopupOpen && (
                        <div className="popup-container">
                            {isVerifying ? (
                                <div>
                                    <div className="spinner"></div>
                                    <div className="spinner-text">Verifying Credentials...</div>
                                </div>
                            ) : (
                                <div className="popup">
                                    <div className="popup-header">
                                        <div className="popup-title">Credential Verification Details</div>
                                        <button className="popup-close" onClick={handlePopupClose}>
                                            <FontAwesomeIcon className='fontIcon21cms' icon={faCircleXmark} />
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div className="popup-body">
                                        {isVerificationLoading[0] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Issuer...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>

                                                    </div>
                                                    <div className="verification-label">Verified Issuer:</div>

                                                    <div className="verification-result1 row">

                                                        <a href='https://cms.law/' target='blank'>
                                                            <div className='issuerNamepopcms'>

                                                                {/* <FontAwesomeIcon className='issuerfontIcon1' icon={faMedal} /> */}

                                                                {issuer}

                                                                <FontAwesomeIcon className='issuerfontIconcms' icon={faArrowUpRightFromSquare} />


                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issuer is Valid:</div> */}

                                                    <div className="verification-result1cms">
                                                        <div>This credential was issued by a trusted and verified source.</div>


                                                    </div>
                                                </div>

                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[1] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate ID...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Credential Authenticity:</div>

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>Successfully Verified.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1cms">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>This credential’s authenticity has been independently confirmed.</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}

                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div className="footer22">
                                                            <div className='verify1'>
                                                                {/* <FontAwesomeIcon className='fontIcon22' icon={faCheckCircle} /> */}
                                                                Certificate ID: {lastPart}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[2] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Owner...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                    </div>
                                                    <div className="verification-label">Issued to:</div>

                                                    <div className="verification-result1">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>{userName}</div>

                                                    </div>
                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        {/* <div className="success">&#10004;</div> */}
                                                    </div>
                                                    {/* <div className="verification-label">Issued by:</div> */}

                                                    <div className="verification-result1cms">
                                                        {/* {verificationData[1] && <div>{verificationData[1]}</div>} */}
                                                        <div>The recipient of this credential has been verified.</div>

                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[3] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Checking Certificate Status...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>

                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                        <div></div>
                                                    </div>

                                                    <div className="verification-label">Issued on:</div>

                                                    <div className="verification-result1">
                                                    {formatExcelDate(responseData[0].vc.credentialSubject[0].startDate)}
                                                    </div>


                                                </div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success">&#10004;</div>
                                                        <div></div>
                                                    </div>

                                                    <div className="verification-label">Expires on:</div>

                                                    <div className="verification-result1">
                                                    {formatExcelDate(responseData[0].vc.credentialSubject[0].endDate)}
                                                    </div>

                                                </div>


                                                <hr></hr>
                                            </div>

                                        )}
                                        {isVerificationLoading[4] ? (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="spinner1"></div>
                                                    </div>
                                                    <div className="verification-label">Verifying Credential Data...</div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        ) : (
                                            <div>
                                                <div className="verification-item">
                                                    <div className="verification-result">
                                                        <div className="success1"><img src={check}></img></div>
                                                    </div>
                                                    <div className="verification-label"></div>

                                                    <div className="verification-result11">
                                                        <div className='verified'>{certificationName} Verified</div>

                                                        <div className='verified1'>This digital credential was securely issued by {issuer} and remains valid.</div>


                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div>

                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <footer className="footerr">
                        <div className="footerr-text">
                            © 2025 EarthID. All Rights Reserved.
                        </div>
                    </footer>
                </div>
            ) : (
                Swal.fire({
                    title: "No Certificate Found",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Go Back",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to the home page
                        window.location.href = "/uploadDoc";
                    }
                })
            )}
        </>

    );
};

export default CMSCert;
