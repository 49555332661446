import React, { Component } from "react";
import openSocket from "socket.io-client";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home/home";
import Certificate from "./components/Certificate/Certificate";
import CreateCred from "./generateCred";
import EmpCertificate from "./components/EmpCred/certificate";
import DocumentMetadata from "./components/UploadDoc/UploadPdf";
import Admin from "./components/Admin/Admin";
import ServiceNow from "./components/certserviceNow/serviceNow";
import Subscribe from "./components/Subscribe/subs";
import IDCertificate from "./components/IDCertificate/NewIDCertificate";
import GradCertificate from "./components/GradCert/GradCert";
import Baseui from "./components/BaseUI/baseui";
//import Project from "./components/Certificate/Certificate";
import Training from "./components/TrainCert/Certificate";
//import Professional from "./components/ProfMemCert/Certificate";
import DonateCert from "./components/DonateCert/Certificate";
import ExcelCert from "./components/ExcelCert/Certificate";
import Membership from "./components/MembershipCert/Certificate";
import GBACert from "./components/GBACert/gbaCertificate";
import AllCreds from "./components/AllCreds/allCreds";
import AllCredsGBA from "./components/AllCredsGBA/allCreds";
import AllCredsNew from "./components/AllCredsGBA/allCredNew";
import VerifyPdf from "./components/VerifyPDF/verifyPdf";
import VerifyPdfFail from "./components/VerifyPDF/verifyPdfFail";
import CMSCert from "./components/CertCMS/gbaCertificate";
import EvisaUI from "./components/EvisaDemoUI/evisa";

// export const isTestnet = true;
// const socketURL =
//   isTestnet === true
//     ? "https://socket-stagev2.myearth.id"
//     : "https://socketv2.myearth.id";
// const socket = openSocket(socketURL, {
//   transports: ["websocket", "polling", "flashsocket"],
// });
// let socketId;
// socket.on("connect", () => {
//   socketId = socket.id;
//   console.log(`I'm connected with the back-end`);
// });



class App extends Component {


  render() {
    return (
      <div className="App">


          <div className="content">
            <Router>
              <Switch>
              <Route exact path="/" component={Baseui} />
              <Route path="/uploadDoc" component={DocumentMetadata} />
              <Route path="/admin/" component={Admin} />
              {/* <Route path="/certificate/project-completion/:userName" component={Project} /> */}
              <Route path="/certificate/employment/:userName" component={IDCertificate} />
              <Route path="/:userName/credential/:certType" component={Training} />
              {/* <Route path="/certificate/professional-membership/:userName" component={Professional} /> */}
              <Route path="/certificate/membership/:userName" component={Membership} />
              <Route path="/certificate/alumni/:userName" component={DonateCert} />
              <Route path="/certificate/achievement/:userName" component={ExcelCert} />
              <Route path="/v1/en/certificate/GBA/:userName/:certType/:certId" component={GBACert} />
              <Route path="/v1/en/certificate/:issuer/:userName/:certType/:certId" component={CMSCert} />
              <Route path="/certificate/ServiceNow/" component={ServiceNow} />
              <Route path="/transactions" component={Subscribe} />
              <Route path="/certificates/:userName" component={AllCreds} />
              <Route path="/v1/en/certificate/:issuer/:userName" component={AllCredsNew} />
              <Route path="/v1/en/verify/success/:issuer/:userName/:docType/:docId" component={VerifyPdf} />
              <Route path="/v1/en/verify/failed/:issuer/:userName/:docType/:docId" component={VerifyPdfFail} />
              <Route path="/v1/en/evisa-uk/verification" component={EvisaUI} />
              </Switch>
            </Router>
          </div>
      </div>
    );
  }
}

export default App;
