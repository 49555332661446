import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './evisa.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamond } from '@fortawesome/free-solid-svg-icons';

const EvisaDemoUI = () => {
    const [form, setForm] = useState({
        shareCode: '',
        dob: '',
        jobTitle: '',
        orgName: '',
        purpose: ''
    });

    const [errors, setErrors] = useState({});
    const [response, setResponse] = useState(null);

    const validate = () => {
        const newErrors = {};
        if (!form.shareCode) newErrors.shareCode = 'Share Code is required';
        if (!form.dob) newErrors.dob = 'Date of Birth is required';
        if (!form.jobTitle) newErrors.jobTitle = 'Job Title is required';
        if (!form.orgName) newErrors.orgName = 'Organisation Name is required';
        if (!form.purpose) newErrors.purpose = 'Purpose is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const handleSubmit = async () => {
        if (!validate()) return;
        setResponse(null)
        Swal.fire({
            title: 'Verifying...',
            text: 'Please wait while we verify the details.',
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
        });

        try {
            const result = await axios.post('https://evisauk.myearth.id/api/verify', form, {
                headers: { 'Content-Type': 'application/json' },
            });
            Swal.close();
            setResponse(result.data);

            Swal.fire({
                icon: 'success',
                title: 'Verification Successful',
                text: 'The immigration profile has been successfully verified.',
                confirmButtonText: 'OK',
            });
            
        } catch (error) {
            const errorMessage = error.response.data.error || 'Something went wrong. Please try again.';
            Swal.fire({
                icon: 'error',
                title: 'Verification Failed',
                html: `
                  <div style="text-align: left; font-size: 14px; line-height: 1.5;">
                    ${errorMessage
                      .split('\n')
                      .map(line => `<p>${line}</p>`)
                      .join('')}
                  </div>
                `,
                confirmButtonText: 'OK',
              });
              
            setResponse({ error: errorMessage });
            console.error('API Error:', errorMessage);
        }
    };

    const isFormComplete = Object.values(form).every((val) => val.trim() !== '');

    return (
        <div className="containerev">
            <div className="wrapper">
                <div className="left-pane">
                    <h3>UK Immigration Verification</h3>
                    <div className='instext'>
                        <p>Fill in the details below to run verification</p>
                    </div>
                    <div className="input-container">
                    <label className="input-label">Share Code</label>
                        <input
                            type="text"
                            name="shareCode"
                            placeholder="Share Code"
                            value={form.shareCode}
                            onChange={handleChange}
                            required
                        />
                        {errors.shareCode && <small className="text-danger">{errors.shareCode}</small>}
                        <label className="input-label">Date of Birth</label>
                        <input
                            type="text"
                            name="dob"
                            placeholder="Date of Birth (dd/mm/yyyy)"
                            value={form.dob}
                            onChange={handleChange}
                            required
                        />
                        {errors.dob && <small className="text-danger">{errors.dob}</small>}
                        <label className="input-label">Job Title</label>
                        <input
                            type="text"
                            name="jobTitle"
                            placeholder="Job Title"
                            value={form.jobTitle}
                            onChange={handleChange}
                            required
                        />
                        {errors.jobTitle && <small className="text-danger">{errors.jobTitle}</small>}
                        <label className="input-label">Organisation Name</label>
                        <input
                            type="text"
                            name="orgName"
                            placeholder="Organisation Name"
                            value={form.orgName}
                            onChange={handleChange}
                            required
                        />
                        {errors.orgName && <small className="text-danger">{errors.orgName}</small>}
                        <label className="input-label">Purpose of Verification</label>
                        <input
                            type="text"
                            name="purpose"
                            placeholder="Purpose"
                            value={form.purpose}
                            onChange={handleChange}
                            required
                        />
                        {errors.purpose && <small className="text-danger">{errors.purpose}</small>}

                        <button
                            onClick={() => {
                                if (!isFormComplete) validate();  // force validation messages
                                handleSubmit();
                            }}
                            disabled={!isFormComplete}
                            className={!isFormComplete ? 'disabled-btn' : ''}
                        >
                            Verify
                        </button>
                    </div>
                </div>
                <div className='linee'></div>
                <div className="right-pane">
                    <h3>Verification Data</h3>
                    {response && (
                        <div className="response-container">
                            <div className="profile-header">
                                <div className="name">{response.name}</div>
                                {response.profileImage && (
                                    <img src={response.profileImage} alt="Profile" />
                                )}
                            </div>

                            {/* Main fields except allowed and restricted */}
                            {Object.entries(response).map(([key, value]) => {
                                if (['name', 'profileImage', 'allowed', 'restricted'].includes(key)) return null;
                                return (
                                    <div key={key} className="detail-row">
                                        <div className="label">{key} </div>
                                        <div className="value">{value}</div>
                                    </div>
                                );
                            })}

                            {response.allowed && response.allowed.length > 0 && (
                                <div className="permission-section">
                                    <div className="permission-label" id='allowed'>Allowed :</div>
                                    <ul className="permission-list">
                                        {response.allowed.map((item, index) => (
                                            <li key={index}>
                                                <FontAwesomeIcon icon={faDiamond} className="bullet-icon" />
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {response.restricted && response.restricted.length > 0 && (
                                <div className="permission-section">
                                    <div className="permission-label" id='restricted'>Restricted :</div>
                                    <ul className="permission-list">
                                        {response.restricted.map((item, index) => (
                                            <li key={index}>
                                                <FontAwesomeIcon icon={faDiamond} className="bullet-icon" />
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                        </div>


                    )}

                </div>
            </div>
        </div>
    );
};

export default EvisaDemoUI;
